<template>
  <DeleteThreadModal :isVisible="deleteChatModalVisible" :chatId="selectedChatId" @hide="deleteChatModalVisible = false" />


  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <div class="simple-text logo-normal">
          CHATS
        </div>
        <button class="btn btn-default animation-on-hover" @click="openNewChat()">New chat</button>

      </div>
      <div class="threads" v-if="websocketStore">
        <div v-for="thread in websocketStore.threads" class="thread" v-bind:key="thread.time" @click="goToChat(thread)">

          <div
            :class="thread.chat_uuid == websocketStore.selectedChat ? 'thread-title selected-thread' : 'thread-title'">
            <div class="d-flex flex-column" style="flex: 1;">
              <div class="title">{{ getTitle(thread) }}</div>
              <div class="date">{{ new Date(thread.time * 1000).toLocaleString() }}</div>
            </div>
            <div @click.stop="removeThread(thread)">
              <i class="tim-icons icon-simple-remove"></i>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useWebSocketStore } from '@/store/webSocketStore';
import DeleteThreadModal from '@/components/Dashboard/DeleteThreadModal.vue';


export default {
  props: {
    backgroundColor: {
      type: String,
      default: "vue",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DeleteThreadModal,
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      threads: [{ date: "10.10.2024", title: "neki title chata test", id: "e66bf693-940b-4007-b73f-fc32de9c0076" }],
      websocketStore: null,
      deleteChatModalVisible: false,
      selectedChatId: ""
    };
  },
  methods: {
    goToChat(thread) {
      this.websocketStore.setSelectedChat(thread.chat_uuid);
    },
    openNewChat() {
      this.websocketStore.setSelectedChat("");
    },
    getTitle(thread) {
      if (thread.title) {
        return thread.title
      }
      return "Chat started at ..."
    },
    removeThread(thread) {
      console.log(thread);
      this.selectedChatId = thread.chat_uuid;
      this.deleteChatModalVisible = true;
    }
  },
  mounted() {
    this.websocketStore = useWebSocketStore();
  },
};
</script>


<style>
.threads {
  padding-left: 15px;
  padding-top: 15px;
  color: white;
}

.threads .selected-thread {
  color: #344675;
}

.thread {
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 0px 5px;
  border-radius: 5px;
  transition: 0.5s;
}

.thread:hover {
  background-color: #ffffff30;
}

.thread-title {
  display: flex;
  align-items: center;
}

.thread:hover .date,
.thread:hover .thread-title {
  cursor: pointer;
}

.thread .date {
  font-size: 12px;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body .sidebar .logo .simple-text {
  font-weight: bold;
  font-size: 18px;
}

.logo .btn {
  padding: 12px;
}

body .sidebar .logo {
  padding-left: 15px;
}

.title {
  font-weight: 500;
}
</style>