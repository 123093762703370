<template>
    <a-modal title="" style="top: 20%; width: 350px;" :footer="null" :open="props.isVisible" @cancel="emit('hide')"
        :closable="false">
        <div>
            <p>Are you sure you want to delete this chat?</p>
        </div>
        <div class="buttons">
            <button type="button" @click="emit('hide')" class="animation-on-hover btn btn-warning btn-sm">No</button>
            <button type="button" @click="deleteChat()" class="animation-on-hover btn btn-success btn-sm">Yes</button>
        </div>


    </a-modal>
</template>


<script setup>
import { defineProps, defineEmits } from 'vue';
import { useWebSocketStore } from '@/store/webSocketStore';

const websocketStore = useWebSocketStore();

const props = defineProps(['isVisible', 'chatId']);
const emit = defineEmits(['hide']);

function deleteChat() {
    let request = {
        "action": "REMOVE_CHAT",
        "uuid": props.chatId,
    };
    websocketStore.sendMessage(JSON.stringify(request));
console.log(request);
    if(websocketStore.selectedChat == props.chatId.value){
        websocketStore.setSelectedChat("")
    }

    emit('hide');
}

</script>


<style lang="scss" scoped>
/* From Uiverse.io by satyamchaudharydev */
.buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
}
</style>